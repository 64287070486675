import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import CaseStudies from '../components/AboutUs/CaseStudies'
import Seo from "../components/App/Seo"

// Functional component
const Kundenprojekte = () => {
  return (
    <Layout>
      <Seo title={"Übersicht ausgewählter LOGENTIS Kundenprojekte"}
           description={"Unser Kunden sind das Zentrum unseres Handelns. Nichts kann " +
           "dies besser darstellen, als die Geschichten der erfolgreichen Intralogistikprojekte."}
           image={"/images/og/logentis-main-og.png"}
      />
      <Navbar />
      <PageBanner
        pageTitle="Auswahl an Kundenprojekten"
        homePageText="Über uns"
        homePageUrl="/ueber-uns/"
        activePageText="Kundenprojekte"
      />
      <CaseStudies />
      <Footer />
    </Layout>
  );
}

export default Kundenprojekte
